export const ComponentRequestStatus = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export const TranscriptionStatuses = {
  READY_FOR_PROCESS: 'ready-for-process',
  IN_PROGRESS: 'in-progress',
  FAILED: 'failed',
  CLEARED: 'cleared',
  DONE: 'done',
}

export const TranscriptionStatusesLabels = {
  [TranscriptionStatuses.READY_FOR_PROCESS]: 'Waiting for process',
  [TranscriptionStatuses.IN_PROGRESS]: 'Transcription in progress',
  [TranscriptionStatuses.FAILED]: 'Transcription failed',
  [TranscriptionStatuses.CLEARED]: 'Transcription cleared',
  [TranscriptionStatuses.DONE]: 'Transcription done',
}

export const MeetingStatuses = {
  WAITING_FOR_TRANSCRIPTION: 'waiting-for-transcription',
  READY_FOR_AI_PROCESSING: 'ready-for-ai-processing',
  IN_AI_PROCESSING_QUEUE: 'in-ai-processing-queue',
  AI_PROCESSING: 'ai-processing',
  AI_PROCESSING_FAILED: 'ai-processing-failed',
  TRANSCRIPTION_FAILED: 'transcription-failed',
  READY: 'ready',
  CLEARED: 'cleared',
}

export const MeetingStatusesLabels = {
  [MeetingStatuses.WAITING_FOR_TRANSCRIPTION]: 'Waiting for transcription',
  [MeetingStatuses.TRANSCRIPTION_FAILED]: 'Transcribing failed',
  [MeetingStatuses.READY_FOR_AI_PROCESSING]: 'Ready for processing',
  [MeetingStatuses.IN_AI_PROCESSING_QUEUE]: 'Taken processing',
  [MeetingStatuses.AI_PROCESSING]: 'Processing by AI',
  [MeetingStatuses.AI_PROCESSING_FAILED]: 'Processing error',
  [MeetingStatuses.READY]: 'Ready',
  [MeetingStatuses.CLEARED]: 'Meeting cleared',
}

export const RecordLanguagesSelectboxOptions = [
  {
    index: 'ukrainian',
    title: 'Ukrainian',
  },
  {
    index: 'english',
    title: 'English',
  },
  {
    index: 'spanish',
    title: 'Spanish',
  },
  {
    index: 'german',
    title: 'German',
  },
  {
    index: 'italian',
    title: 'Italian',
  },
  {
    index: 'french',
    title: 'French',
  },
  {
    index: 'polish',
    title: 'Polish',
  },
  {
    index: 'swedish',
    title: 'Swedish',
  },
  {
    index: 'japanese',
    title: 'Japanese',
  },
  {
    index: 'chinese',
    title: 'Chinese',
  },
]

export const WorkspaceStatisticTypes = {
  MEETINGS_PROCESSED: 'total-meetings',
  TOTAL_TRANSCRIPTION_TIME: 'total-transcription-time',
  BALANCE: 'balance',
}

export const WorkspaceStatisticTypesNames = {
  [WorkspaceStatisticTypes.MEETINGS_PROCESSED]: 'Processed meetings',
  [WorkspaceStatisticTypes.TOTAL_TRANSCRIPTION_TIME]: 'Minutes of media transcribed',
  [WorkspaceStatisticTypes.BALANCE]: 'Credit balance',
}

export const WORKSPACE_PLANS = {
  PERSONAL: 'personal',
  TEAM: 'team',
}

export const WORKSPACE_PLANS_NAMES = {
  [WORKSPACE_PLANS.PERSONAL]: 'Personal',
  [WORKSPACE_PLANS.TEAM]: 'Team',
}

export const WORKSPACE_USER_ROLES = {
  OWNER: 'owner',
  ADMIN: 'admin',
  MEMBER: 'member',
}

export const RECORD_SOURCE_TYPES = {
  ACCOUNT: 'account',
  API_REQUEST: 'api-request',
}

export const MEETING_SOURCE_TYPES = {
  ACCOUNT: 'account',
  API_REQUEST: 'api-request',
}

export const WORKSPACE_USER_ROLES_NAMES = {
  [WORKSPACE_USER_ROLES.OWNER]: 'Owner',
  [WORKSPACE_USER_ROLES.ADMIN]: 'Admin',
  [WORKSPACE_USER_ROLES.MEMBER]: 'Member',
}

export const WORKSPACE_USER_STATUSES = {
  ACTIVE: 'active',
  INVITED: 'invited',
  DEACTIVATED: 'deactivated',
}

export const WORKSPACE_USER_STATUSES_NAMES = {
  [WORKSPACE_USER_STATUSES.ACTIVE]: 'Active',
  [WORKSPACE_USER_STATUSES.INVITED]: 'Invited',
}

export const PRICE = {
  FIRST_LLM_REQUEST: 0.5,
  SEAT_PRICE: 17,
}

export const STRIPE_SUBSCRIPTION_STATUSES = {
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  CANCELED: 'canceled',
  UNPAID: 'unpaid',
}

export const ITEMS_PER_PAGE = 25
