import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMeeting } from '../../../../reducers/meetings/meeting-details'
import { MeetingStatuses } from '../../../../constants'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Loader from '../../../common/loader/Loader'
import { classNames } from '../../../../utils/className'
import ViewSidebarV2 from './SidebarV2'
import { isJsonString } from '../../../../utils/json-processor'
import { jsonrepair } from 'jsonrepair'
import { cleanJsonString } from '../../../../utils/json-processor'

const statusesMessages = {
  [MeetingStatuses.AI_PROCESSING]: {
    title: 'This meeting is currently processing by our AI.',
    subtitle: 'Please wait, it can takes a few minutes.',
  },
  [MeetingStatuses.TRANSCRIPTION_FAILED]: {
    title: 'Transcription process failed',
    subtitle: 'Please contact our support team.',
  },
  [MeetingStatuses.WAITING_FOR_TRANSCRIPTION]: {
    title: 'This meeting will start processing soon',
    subtitle: 'Please wait, it can takes a few minutes.',
  },
  [MeetingStatuses.AI_PROCESSING_FAILED]: {
    title: 'Meeting processing failed',
    subtitle: 'Please contact our support team for help.',
  },
  [MeetingStatuses.READY_FOR_AI_PROCESSING]: {
    title: 'Meeting is waiting for processing',
    subtitle: 'Please wait.',
  },
  [MeetingStatuses.CLEARED]: {
    title: 'Meeting data was cleared',
    subtitle: 'All meeting data was cleared after analysis or expired.',
  },
}

export default function MeetingDetailsV2({ meeting }) {
  const storedMeeting = useSelector(selectMeeting)

  if (!meeting) {
    meeting = storedMeeting
  }

  const [selectedTaskUuid, selectTaskUuid] = useState(null)
  const [selectedTask, selectTask] = useState(null)

  useEffect(() => {
    if (selectedTaskUuid && meeting) {
      const selectedResult = meeting.processingResults.find((result) => result.task.uuid === selectedTaskUuid)
      selectTask(selectedResult.task)
    }
  }, [selectedTaskUuid])

  let sections = []
  if (meeting && meeting.uuid && selectedTaskUuid) {
    const json = meeting.processingResults.find((result) => result.task.uuid === selectedTaskUuid)

    if (json && json.result) {
      const jsonData = JSON.parse(json.result)
      const sectionsRawJson = jsonData.data || jsonData

      sections = sectionsRawJson.map((sectionJson) => {
        // Fix JSON if it is corrupted
        if (sectionJson.contentType === 'list' && typeof sectionJson.value === 'string') {
          let cleanedValue = cleanJsonString(sectionJson.value)
          if (!isJsonString(cleanedValue)) {
            cleanedValue = jsonrepair(cleanedValue)
          }

          if (isJsonString(cleanedValue)) {
            // Recursively check the parsed value
            sectionJson.value = JSON.parse(cleanedValue)
          } else {
            sectionJson.value = [
              { title: 'Data parsing error', content: 'The error occurs during the result preparation' },
            ]
          }

          return sectionJson
        }

        return sectionJson
      })
    }
  }

  return (
    <div>
      <div className={'my-4'}>
        <p className="text-sm font-semibold text-gray-500">{meeting.typeV2.name}</p>
        <div className="mt-2 text-sm text-gray-500 sm:col-span-2 whitespace-pre-line">{meeting.typeV2.description}</div>
      </div>
      <div className="relative pt-4 lg:flex lg:gap-x-16">
        {meeting.status === MeetingStatuses.READY && (
          <>
            <ViewSidebarV2 selectedTaskUuid={selectedTaskUuid} selectTask={selectTaskUuid} meeting={meeting} />
            <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-8">
              <div
                className={
                  'flex flex-row justify-between content-center items-center pb-8 mb-8 border-b border-gray-100'
                }
              >
                <div className={'flex flex-col'}>
                  <div className={'text-gray-700 text-xl font-bold'}>{selectedTask?.name}</div>
                  <div className={'text-gray-500 text-sm font-normal'}>{selectedTask?.description}</div>
                </div>
              </div>
              <div className="text-gray-500 text-sm whitespace-pre-line">
                {sections &&
                  sections.map((section, sectionIndex) => {
                    return (
                      <div key={`section-${sectionIndex}`} className={'border-b border-gray-100 mb-8'}>
                        {section.contentType === 'text' && (
                          <div className={'pb-8'}>
                            {section.name && (
                              <h3 className={'text-lg font-semibold text-indigo-600 pb-8'}>{section.name}</h3>
                            )}
                            <div className={'text-gray-700 text-sm leading-6'}>{section.value}</div>
                          </div>
                        )}
                        {section.contentType === 'list' && (
                          <div>
                            {section.name && (
                              <h3 className={'text-lg font-semibold text-indigo-600 pb-8'}>{section.name}</h3>
                            )}
                            {section.value.map((listItem, itemIndex) => (
                              <div key={`section-${sectionIndex}-list-item-${itemIndex}`} className={'pb-4 mb-4'}>
                                <h3 className={'text-base font-semibold text-gray-700 pb-2'}>{listItem.title}</h3>
                                <div className={'text-gray-700 text-sm leading-6'}>{listItem.content}</div>
                              </div>
                            ))}
                          </div>
                        )}

                        {section.contentType === 'table' && (
                          <div>
                            {section.name && (
                              <h3 className={'text-lg font-semibold text-indigo-600 py-4 pb-8'}>{section.name}</h3>
                            )}

                            <div
                              className={classNames(
                                'grid gap-2',
                                section.cols.length === 1 && 'grid-cols-1',
                                section.cols.length === 2 && 'grid-cols-2',
                                section.cols.length === 3 && 'grid-cols-3',
                                section.cols.length === 4 && 'grid-cols-4',
                                section.cols.length === 5 && 'grid-cols-5',
                                section.cols.length === 6 && 'grid-cols-6',
                              )}
                            >
                              {section.cols.map((col, colIndex) => (
                                <div
                                  key={`section-${sectionIndex}-col-header-${colIndex}`}
                                  className={'p-2 mb-2 bg-gray-50 rounded-md hover:bg-indigo-50'}
                                >
                                  <div className={'text-gray-700 font-semibold text-sm leading-6'}>
                                    {col.name || col}
                                  </div>
                                </div>
                              ))}
                            </div>
                            {section.rows.map((row, rowIndex) => (
                              <div
                                key={`section-${sectionIndex}-row-${rowIndex}`}
                                className={classNames(
                                  'grid gap-x-2 border-b border-gray-100 hover:bg-gray-50 rounded-md',
                                  section.cols.length === 1 && 'grid-cols-1',
                                  section.cols.length === 2 && 'grid-cols-2',
                                  section.cols.length === 3 && 'grid-cols-3',
                                  section.cols.length === 4 && 'grid-cols-4',
                                  section.cols.length === 5 && 'grid-cols-5',
                                  section.cols.length === 6 && 'grid-cols-6',
                                )}
                              >
                                {row.map((cell, cellIndex) => (
                                  <div
                                    key={`section-${sectionIndex}-row-${rowIndex}-cell-${cellIndex}`}
                                    className={'p-2 mb-2'}
                                  >
                                    {cell.responseType !== 'boolean' && cell.value}
                                    {cell.responseType === 'boolean' && (
                                      <>
                                        {parseInt(cell.value) === 1 && (
                                          <div className={'flex flex-row items-center'}>
                                            <div
                                              className={
                                                'w-6 h-6 bg-green-600 rounded-md flex items-center justify-center mr-2'
                                              }
                                            >
                                              <CheckIcon className={'w-4 h-4 text-white'} />
                                            </div>
                                            True
                                          </div>
                                        )}
                                        {parseInt(cell.value) === 0 && (
                                          <div className={'flex flex-row items-center'}>
                                            <div
                                              className={
                                                'w-6 h-6 bg-red-600 rounded-md flex items-center justify-center mr-2'
                                              }
                                            >
                                              <XMarkIcon className={'w-4 h-4 text-white'} />
                                            </div>
                                            False
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )
                  })}
              </div>
            </div>
          </>
        )}
        {meeting.status !== MeetingStatuses.READY && (
          <div className="mt-12 w-full text-center">
            {(meeting.status === MeetingStatuses.AI_PROCESSING ||
              meeting.status === MeetingStatuses.WAITING_FOR_TRANSCRIPTION) && (
              <div className={'mx-auto w-12 mb-4'}>
                <Loader size={12} />
              </div>
            )}
            <h3 className="mt-2 text-sm font-semibold text-gray-900">{statusesMessages[meeting.status]?.title}</h3>
            <p className="mt-1 text-sm text-gray-500">{statusesMessages[meeting.status].subtitle}</p>
          </div>
        )}
      </div>
    </div>
  )
}
