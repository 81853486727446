import { MeetingStatuses } from '../../constants'

export const MeetingStatusClasses = {
  [MeetingStatuses.READY]:
    'text-green-800 bg-green-50 ring-green-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
  [MeetingStatuses.CLEARED]:
    'text-gray-800 bg-gray-50 ring-gray-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
  [MeetingStatuses.WAITING_FOR_TRANSCRIPTION]:
    'text-yellow-800 bg-yellow-50 ring-yellow-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
  [MeetingStatuses.TRANSCRIPTION_FAILED]:
    'text-red-800 bg-red-50 ring-red-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
  [MeetingStatuses.AI_PROCESSING]:
    'text-yellow-800 bg-yellow-50 ring-yellow-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
  [MeetingStatuses.IN_AI_PROCESSING_QUEUE]:
    'text-yellow-800 bg-yellow-50 ring-yellow-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
  [MeetingStatuses.READY_FOR_AI_PROCESSING]:
    'text-yellow-800 bg-yellow-50 ring-yellow-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
  [MeetingStatuses.AI_PROCESSING_FAILED]:
    'text-red-800 bg-red-50 ring-red-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
}
